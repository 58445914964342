<template>
  <div class="recruitment-center">
    <div class="to-h">
      <h1 class="animate__animated animate__bounceInRight" align="center">
        RECRUITMENT CENTER
      </h1>
      <h2 class="animate__animated animate__bounceInRight" align="center">
        招聘中心
      </h2>
    </div>

    <a-menu v-model="current" mode="horizontal" class="recruit-menu">
      <a-menu-item v-for="item in recList" :key="item.id" @click="show(item)" class="recruit-menu-item">
        {{ item.text }}</a-menu-item>
    </a-menu>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      recList: [
        
        {
          id: 1,
          text: "社会招聘",
          name: "social",
        },
      ],
      current: [1],
    };
  },
  methods: {
    show(item) {
      this.$router.push({ name: item.name });
    },
  },
  mounted() {
    this.$router.push({ name: this.recList[0].name });
  },
};
</script>

<style lang="less">
.recruitment-center {
  .to-h {
    width: 100%;
    position: absolute;
    top: 320px;

    h1 {
      color: #ffffff;
      font-size: 72px;
      font-family: Quantum;
      font-weight: 400;
    }

    h2 {
      color: #ffffff;
      font-size: 44px;
      font-family: Source Han Sans CN;
      font-weight: 500;
    }
  }
  .recruit-menu {
      .recruit-menu-item {
        margin-left: 47%;
        text-align: center;
      }
    }
}

@media all and (max-width: 700px) {
  .recruitment-center {
    .to-h {
      width: 100%;
      position: absolute;
      top: 260px;

      h1 {
        color: #ffffff;
        font-size: 100px;
        font-family: Quantum;
        font-weight: 400;
      }

      h2 {
        color: #ffffff;
        font-size: 54px;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
    }
    
    .recruit-menu {
      height: 120px;
      line-height: 120px;
      .recruit-menu-item {
        width: 250px;
        text-align: center;
        font-size: 48px;
      }
    }
  }
}</style>
